<template>
  <div class="order_refund" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="退款申请"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div class="refund_content">
      <!-- 商品信息 -->
      <div class="order_info">
        <div class="order_title">退款商品</div>
        <!-- <div class="info_detail">
          <img src="../../../assets/ShopImg/t.jpg" alt="" />
          <div class="detail_content">
            <div>旭苁抽屉画架画板套装样木木制便 携式油画架素描水粉写生美术</div>
            <div><span>150*60com</span></div>
            <div><span>￥388</span><span>×1</span></div>
          </div>
        </div> -->

        <van-checkbox-group v-model="result" ref="checkboxGroup">
          <van-checkbox
            :name="i"
            class="order_info"
            v-for="(item, i) in detailList"
            :key="i"
            @click="changeCheckbox"
          >
            <div class="info_detail">
              <img :src="item.SpecImg" alt="" />
              <div class="detail_content">
                <div>
                  {{
                    item.GoodsName.length > 25
                      ? item.GoodsName.substr(0, 25) + "..."
                      : item.GoodsName
                  }}
                </div>
                <div>
                  <span>{{
                    item.SpecName.length > 15
                      ? item.SpecName.substr(0, 15) + "..."
                      : item.SpecName
                  }}</span>
                </div>
                <div>
                  <span v-if="item.Points > 0" style="font-size: 12px"
                    >{{ item.Points }}积分 +</span
                  >
                  ￥<span>{{ item.TotalMoney }}</span>
                </div>
              </div>
            </div>
            <!-- 数量 -->
            <div class="info_num">
              x{{ item.RefundCount }}
              <!-- <van-stepper
                v-model="item.RefundCount"
                @change="onChange()"
                min="1"
                :max="item.GoodsCount"
              /> -->
            </div>
          </van-checkbox>
        </van-checkbox-group>
      </div>

      <!-- 选择类型 -->
      <div class="refund_type">
        <div class="type_title">退款类型</div>
        <!-- <div
          class="type_tab"
          v-for="(item, index) in tab_list"
          :key="index"
          @click="gotoApplyRefund"
          :style="{ marginBottom: index == 2 ? '0' : '0.9375rem' }"
        >
          <div class="tab_icon"></div>
          <div class="tab_content">
            <div>{{ item.title }}</div>
            <div>{{ item.content }}</div>
          </div>
        </div> -->

        <van-radio-group
          v-model="refundType"
          v-for="(item, index) in tab_list"
          :key="index"
          class="type_tab"
        >
          <van-radio :name="item.title">
            <!-- <div class="tab_icon"></div> -->
            <div class="tab_content">
              <div>{{ item.title }}</div>
              <div>{{ item.content }}</div>
            </div>
          </van-radio>
        </van-radio-group>
      </div>
      <!-- 退款信息 -->
      <div class="refund_info">
        <div class="info_title">退款信息</div>
        <div class="info_tab">
          <div>货物状态<span>*</span></div>
          <div @click="showRefundGoodsStatus = true">
            {{ refundGoodsStatus }}
          </div>
        </div>
        <div class="info_tab">
          <div>退款原因<span>*</span></div>
          <div @click="showReason = true">{{ refundReason }}</div>
        </div>
        <div class="info_tab" style="margin-bottom: 0">
          <div>退款金额<span>*</span></div>
          <div class="tab_money">
            <span v-if="totalPoint > 0" style="font-size: 12px"
              >{{ refundType == "换货" ? 0 : totalPoint }}积分 +</span
            >
            <span>￥</span>
            <span>{{ refundType == "换货" ? 0 : total }}</span>
          </div>
        </div>
      </div>

      <van-popup v-model="showRefundGoodsStatus" round position="bottom">
        <van-picker
          show-toolbar
          title="货物状态"
          :columns="goodsStatusColumns"
          @cancel="showRefundGoodsStatus = false"
          @confirm="onGoodsStatusConfirm"
        />
      </van-popup>
      <van-popup v-model="showReason" round position="bottom">
        <van-picker
          show-toolbar
          title="退款原因"
          :columns="reasonColumns"
          @cancel="showReason = false"
          @confirm="onReasonConfirm"
        />
      </van-popup>
      <!-- 补充描述和凭证 -->
      <div class="refund_text">
        <div class="text_title">退款信息</div>
        <div class="text_content">
          <textarea
            v-model="remark"
            placeholder="补充描述，有助于商家更好的处理售后问题"
          ></textarea>
          <div class="">
            <van-uploader
              v-model="fileList"
              :after-read="afterRead"
              multiple
              upload-text="添加图片"
            />
            <!-- <div>上传凭证</div> -->
          </div>
        </div>
      </div>
      <div class="refund_btn" @click="postAddShopOrdeRefund">提交</div>
    </div>
  </div>
</template>

<script>
import { queryShopOrderDetail, applyRefund } from "@/api/shop";
import { imagesUploadPic } from "@/api/api";
import { Toast, Picker } from "vant";
export default {
  data() {
    return {
      tab_list: [
        { title: "退货退款", content: "已收到货，需要退还已收到的货物" },
        {
          title: "仅退款",
          content: "未收到货，或与卖家协商同意不用退货只退款",
        },
        { title: "换货", content: "已收到货，因尺码之类问题需更换" },
      ], //图标需换
      detail: {},
      detailList: [],
      orderId: 0,
      result: [],
      refundType: "",
      remark: "",
      fileList: [],
      imgList: [],
      total: 0,
      totalPoint: 0,
      showRefundGoodsStatus: false,
      refundGoodsStatus: "请选择>",
      goodsStatusColumns: ["已收到货", "未收到货"],
      showReason: false,
      refundReason: "请选择>",
      reasonColumns: [
        "不喜欢/不想要",
        "空包裹",
        "快递/物流一直未送到",
        "快递/物流无跟踪记录",
        "货物破损已拒收",
        "退运费",
        "规格与商品描述不符",
        "做工粗糙/有瑕疵",
        "质量问题",
        "少件/漏发",
        "卖家发错货",
      ],
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "退款申请";
      this.isWxApplets = false;
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
    this.getQueryShopOrderDetail();
  },
  methods: {
    onGoodsStatusConfirm(value) {
      this.refundGoodsStatus = value;
      // if(value == "已收到货")
      // {
      //    this.refundGoodsStatus = 0;
      // }
      // else if(value == "未收到货")
      // {
      //   this.refundGoodsStatus = 1;
      // }
      this.showRefundGoodsStatus = false;
    },
    onReasonConfirm(value) {
      this.refundReason = value;
      this.showReason = false;
    },
    onChange() {
      this.total = 0;
      this.totalPoint = 0;
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.detailList[this.result[i]];
        console.log(obj);
        this.total += (obj.TotalMoney / obj.GoodsCount) * obj.RefundCount;
        this.totalPoint += (obj.Points / obj.GoodsCount) * obj.RefundCount;
      }
    },
    changeCheckbox() {
      this.total = 0;
      this.totalPoint = 0;
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.detailList[this.result[i]];
        this.total += (obj.TotalMoney / obj.GoodsCount) * obj.RefundCount;
        this.totalPoint += (obj.Points / obj.GoodsCount) * obj.RefundCount;
      }
    },
    // 订单详情
    async getQueryShopOrderDetail() {
      const res = await queryShopOrderDetail(this.orderId);
      this.detail = res.response;
      for (var i = 0; i < res.response.OrderDetailList.length; i++) {
        let obj = {};
        obj = res.response.OrderDetailList[i];
        obj.RefundCount = obj.GoodsCount;
        this.detailList.push(obj);
      }
    },
    //提交退款信息
    async postAddShopOrdeRefund() {
      if (this.refundType == "") {
        Toast({ message: "请选择退款类型" });
        return false;
      }
      let goodsData = [];
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.detailList[this.result[i]];
        // this.total += obj.Price * obj.GoodsCount * 100;
        let data = {};
        data.GoodsId = obj.GoodsId;
        data.GoodsName = obj.GoodsName;
        data.SpecId = obj.SpecId;
        data.SpecName = obj.SpecName;
        data.ImgUrl = obj.SpecImg;
        data.RefundGoodsCount = obj.RefundCount;
        data.BuyCount = obj.GoodsCount;
        data.OrderId = obj.ShopOrderId;
        data.OrderDetailId = obj.Id;
        data.PayMoney = (obj.TotalMoney / obj.GoodsCount) * obj.RefundCount;
        data.PayPoints = (obj.Points / obj.GoodsCount) * obj.RefundCount;
        data.GivePoint = (obj.GivePoint / obj.GoodsCount) * obj.RefundCount;
        data.ApplyRefundMoney =
          this.refundType == "换货"
            ? 0
            : (obj.TotalMoney / obj.GoodsCount) * obj.RefundCount;
        data.ApplyPoint =
          this.refundType == "换货"
            ? 0
            : (obj.Points / obj.GoodsCount) * obj.RefundCount;
        data.UserId = obj.UserId;
        data.UserName = obj.UserName;
        data.RefundType =
          this.refundType == "退货退款"
            ? 0
            : this.refundType == "仅退款"
            ? 1
            : 2;
        data.RefundRemark = this.remark;
        data.RefundPicture = this.imgList.join(",");
        data.RefundReason = this.refundReason;
        data.RefundGoodsStatus = this.refundGoodsStatus == "已收到货" ? 0 : 1;
        goodsData.push(data);
      }
      // console.log(goodsData);
      if (goodsData.length == 0) {
        Toast({
          message: "请选择要退款的商品",
        });
        return false;
      }
      const res = await applyRefund(goodsData);
      if (res.success == true) {
        Toast({
          message: res.msg,
        });
        this.$router.go(-2);
        //  this.$router.push({
        //   path: "/shop/orders/detail",
        //   query: { orderId: res.response.Id }
        // });
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
      // this.detail = res.response;
      // this.$router.push({
      //   path: "/shop/orders/confirmOrder",
      //   query: { goodsData:JSON.stringify(goodsData)}
      // });
    },
    //前去申请退款页面
    gotoApplyRefund() {
      // this.$router.push({
      //   path: "/shop/orders/applyRefund",
      // });
    },
    onClickLeft() {
      window.history.go(-1);
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file.file);
      this.postImagesUploadPic(file.file);
    },
    // 上传文件
    async postImagesUploadPic(file) {
      const formData = new FormData();
      // console.log(file);
      let newFile = await this.common.compressImg(file); //图片压缩
      // console.log(newFile);
      formData.append("file", newFile);
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let url = this.GLOBAL.hostUrl + res.response;
        this.imgList.push(url);
        console.log(this.imgList);
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-ellipsis {
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
//导航栏下边框
/deep/ .van-hairline--bottom::after {
  border: 0;
}
.order_refund {
  .refund_content {
    padding: 0.5rem 0.5rem 0;
    .order_info {
      border-radius: 0.375rem;
      background: #fff;
      width: 100%;
      // height: 12.6rem;
      box-sizing: border-box;
      // padding: 0.9375rem;
      margin-bottom: 0.5rem;
      position: relative;
      &:nth-child(1) {
      }
      .order_title {
        padding: 0.9375rem;
        font-size: 0.875rem;
        color: #333333;
        padding-bottom: 0.9375rem;
        font-weight: 700;
      }
      .info_detail {
        display: flex;
        margin-bottom: 0.9375rem;
        img {
          width: 5.5625rem;
          height: 5.5rem;
          border-radius: 0.25rem;
          margin-right: 0.9375rem;
        }
        .detail_content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div:first-child {
            font-size: 0.875rem;
            color: #333333;
            font-weight: 700;
            margin-right: 1.25rem;
          }
          div:nth-child(2) {
            font-size: 0.75rem;
            color: #858585;
            display: flex;
            justify-content: space-between;
          }
          div:nth-child(3) {
            font-size: 0.75rem;
            color: #fe4a4a;
            span {
              color: #fe4a4a;
              font-size: 0.9375rem;
              font-weight: 700;
            }
          }
        }
      }
      .info_freight {
        display: flex;
        justify-content: flex-end;
        font-size: 0.875rem;
        color: #333333;
        margin-bottom: 1.125rem;
        div:first-child {
          margin-right: 6.18rem;
          span:first-child {
            margin-right: 0.625rem;
          }
          span:nth-child(2) {
            color: #d2d1d1;
          }
        }
        div:nth-child(2) {
          span:first-child {
            font-size: 0.5625rem;
          }
          span:nth-child(2) {
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }
      .info_refund {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0.75rem;
        div {
          width: 4.125rem;
          height: 1.75rem;
          border-radius: 1.875rem;
          border: 1px solid #e8e8e8;
          font-size: 0.75rem;
          color: #999;
          text-align: center;
          line-height: 1.75rem;
        }
      }
      .info_price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 1rem;
        color: #333333;
        div:first-child {
          font-weight: 700;
        }
        div:nth-child(2) {
          color: #fe4a4a;
          span:first-child {
            font-size: 0.5625rem;
          }
          span:nth-child(2) {
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }
      .info_num {
        position: absolute;
        right: 0.9375rem;
        top: 4.2rem;
      }
      .van-checkbox-group {
        margin-left: 0.5rem;
      }
    }
    .refund_type {
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      padding: 0.9375rem;
      position: relative;
      border-radius: 0.375rem;
      margin-top: 0.9375rem;
      .type_title {
        font-size: 0.875rem;
        color: #333333;
        padding-bottom: 0.9375rem;
        font-weight: 700;
      }
      .type_tab {
        display: flex;
        align-items: center;
        margin-bottom: 0.9375rem;
        .tab_icon {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          border: 1px solid #f95a2d;
          margin-right: 0.8125rem;
        }
        .tab_content {
          div:first-child {
            margin-bottom: 0.375rem;
            color: #333333;
            font-size: 0.875rem;
            font-weight: 700;
          }
          div:nth-child(2) {
            font-size: 0.6875rem;
            color: #b8b7b7;
          }
        }
      }
    }
    .refund_info {
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      padding: 0.9375rem;
      position: relative;
      border-radius: 0.375rem;
      margin-top: 0.9375rem;
      .info_title {
        font-size: 0.875rem;
        color: #333333;
        padding-bottom: 0.9375rem;
        font-weight: 700;
      }
      .info_tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.9375rem;
        font-size: 0.8125rem;
        color: #333;
        div:first-child {
          span {
            color: #fe4a4a;
          }
        }
        div:nth-child(2) {
          color: #b8b7b7;
        }
        .tab_money {
          color: #fe4a4a !important;
          span:first-child {
            font-size: 0.625rem !important;
          }
          span:last-child {
            font-size: 1rem !important;
            font-weight: 700;
          }
        }
      }
    }
    .refund_text {
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      padding: 0.9375rem;
      position: relative;
      border-radius: 0.375rem;
      margin-top: 0.9375rem;
      .text_title {
        font-size: 0.875rem;
        color: #333333;
        padding-bottom: 0.9375rem;
        font-weight: 700;
      }
      .text_content {
        width: 100%;
        height: 11.25rem;
        background: #f6f6f6;
        textarea {
          width: 100%;
          height: 50%;
          border: none;
          background: #f6f6f6;
        }
        .text_upload {
          width: 5rem;
          height: 4.5rem;
          border: 1px dashed #d8d7d7;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          margin-left: 0.75rem;
          margin-bottom: 0.75rem;
          border-radius: 0.25rem;
          div:first-child {
            width: 1.375rem;
            height: 1.125rem;
            border: 1px solid #a4a4a4;
          }
          div:last-child {
            font-size: 0.625rem;
            color: #999999;
          }
        }
      }
    }
    .refund_btn {
      width: 17.5rem;
      height: 2.625rem;
      background: linear-gradient(to right, #fc7134, #f54024);
      color: #ffffff;
      font-size: 0.9375rem;
      text-align: center;
      line-height: 2.625rem;
      margin: 4.25rem auto;
      border-radius: 1.875rem;
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
